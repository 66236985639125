<template>
  <van-tabs v-model="active" color="#4581f8" title-active-color="#4581f8" line-height="2px">
    <van-tab title="房屋信息">
      <div class="base-info">
        <div class="left" :class="{'isOld36': $isOld == 1}">基本信息</div>
        <div class="right" :class="{'isOld28': $isOld == 1}" v-if="readonly" @click="readonly = false">编辑</div>
      </div>
      <div class="base-form">
        <van-form :class="{'isOld40': $isOld == 1}">
          <van-field name="所属小区" label="所属小区" readonly v-model="dataForm.subarea"/>
          <van-field name="详细位置" label="详细位置" readonly v-model="dataForm.fullName"/>
          <van-field name="房屋面积㎡" label="房屋面积㎡" :readonly="readonly" v-model="dataForm.areas"/>
          <van-field name="所在楼层" label="所在楼层" readonly v-model="dataForm.floor"/>
          <van-field name="户型" label="户型" v-model="show.doorModel" readonly :clickable="!readonly" @click="readonly ? '' : control.doorModel = true" is-link/>
          <van-popup v-model="control.doorModel" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="doorModelList"
                        @confirm="doorModelConfirm" @cancel="control.doorModel = false" />
          </van-popup>

          <van-field name="房屋属性" label="房屋属性" v-model="show.houseType" readonly :clickable="!readonly" @click="readonly ? '' : control.houseType = true" is-link/>
          <van-popup v-model="control.houseType" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="houseType"
                        @confirm="houseTypeConfirm" @cancel="control.houseType = false" />
          </van-popup>
          <van-cell title="房屋标签" :border="false"></van-cell>
          <div class="house-labels">
            <div class="label" :class="{'isOld30': $isOld == 1}" v-if="houseLabel.length < 1">无标签</div>
            <div class="label" :class="{'isOld30': $isOld == 1}" v-else v-for="item in houseLabel" :key="item.id">{{item.name}}</div>
          </div>
        </van-form>
      </div>
      <div class="btns" v-if="!readonly">
        <van-button class="cancel" @click="oncancel">取消</van-button>
        <van-button class="confirm" @click="onConfirm">提交</van-button>
      </div>
    </van-tab>
    <van-tab title="出租信息">
      <div class="base-info">
        <div class="left" :class="{'isOld36': $isOld == 1}">基本信息</div>
        <!--          <div class="right" :class="{'isOld28': $isOld == 1}" v-if="readonly&&userId==hostId" @click="RenHouse">编辑</div>-->
        <div class="right" :class="{'isOld28': $isOld == 1}" v-if="readonly" @click="RenHouse">编辑</div>
      </div>
      <div class="base-form">
        <van-form :class="{'isOld40': $isOld == 1}">
          <van-cell title="空置房自动出租" >
            <van-switch v-model="dataForm.isAutoRent"  :disabled="readonly" />
          </van-cell>
          <van-cell title="开启出租" >
            <van-switch v-model="dataForm.checked"  :disabled="readonly" />
          </van-cell>
            <van-cell :value="dataForm.safety||'请选择'"
                      input-align="right"  title="安全级别" :readonly="readonly"  @click="openSafe"
                      :is-link="!readonly"
                      class="safe"
            ></van-cell>
            <van-popup v-model="control.safety" position="bottom">
              <van-picker
                  title="安全等级"
                  show-toolbar
                  :columns="safetyList"
                  @confirm="onConfirmSafe"
                  @cancel="onCancelSafe"
              />
            </van-popup>
          <van-field v-model="dataForm.tel" type="number" label="手机号" :readonly="readonly"      placeholder="请输入手机号"/>
          <van-field name="房屋单价(元)" label="房屋单价(元)" :readonly="readonly" v-model="dataForm.money"/>
          <van-field name="房屋面积㎡" label="房屋面积㎡" :readonly="readonly" v-model="dataForm.Tenantareas"
                     type="number"
                     oninput="if(value.length>6)value=value.slice(0,4)"
          />
          <van-cell-group  >
            <van-cell title="房屋设施" :border="false"> </van-cell>
            <van-cell >
              <van-checkbox-group v-model="HasList" direction="horizontal" :disabled="readonly">
                <van-checkbox v-for="item in  TenantList"  :key="item.value" :name="item.value" shape="square" style="padding-bottom: 5px;width:25%;margin-right: 0;">
                  <div :title="item.label">{{item.label}}</div>
                </van-checkbox>
              </van-checkbox-group>
            </van-cell>
          </van-cell-group >
          <van-cell title="定位" title-style="width:30px;" value-class="lat" :value="dataForm.placelat"  @click="getLngLat()">
            <template #right-icon>
              <img src="@/assets/img/icon.png" alt="">
            </template>
          </van-cell>
          <van-field
              v-model="dataForm.message"
              :readonly="readonly"
              rows="4"
              autosize
              label="房屋简介"
              type="textarea"
              maxlength="100"
              placeholder="请输入房屋简介"
              show-word-limit
          />
          <van-cell class="uploadBox">
            <div class="title">上传图片</div>
            <van-col :span="24">
              <upload-file
                  :delBtnShow="!readonly"
                  :file-list="fileList"
                  @filesUpload="filesUpload"
                  @delFile="delFile"
                  :upload-path="uploadPath"
                  :file-type="'image'"/>
            </van-col>
          </van-cell>
        </van-form>

        <lng-lat v-if="lnglatVisible" ref="lnglat" @closed="closed"></lng-lat>
      </div>
      <div class="btns" v-if="!readonly">
        <van-button class="cancel" @click="oncancel">取消</van-button>
        <van-button class="confirm" @click="onConfirm1">提交</van-button>
      </div>

    </van-tab>
    <van-tab title="家庭成员">
            <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
                v-if="dataList.length > 0" offset="10">
      <div class="search-top" v-if="orgRegistryCount>10">
        <img :src="require('@/assets/img/search.png')" class="search-icon" />
        <input v-model="searchValue" type="search" value="搜索" placeholder="请输入姓名、手机号或身份证号" class="search-input"
               @keyup.enter="seaname"/>
        <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel1">
      </div>
          <div class="items" v-for="item in dataList"  @click="goUserInfo(item.id,item.userId,item.relationship,item.relationshipStr)">
            <div class="top">
              <div class="top-left" :class="{'isOld34': $isOld == 1}">{{item.relationshipStr}}</div>
              <div class="top-right" :class="{'isOld28': $isOld == 1}">{{item.registryTypeStr}}</div>
            </div>
            <div class="mid">
              <div class="mid-left">
                <img v-if="item.headImg" class="header" :src="getImageStream(item.headImg)" alt="">
                <img v-else class="header" :src="require('@/assets/img/newVersion/header.png')" alt="">
                <div class="personal-info" :class="{'isOld32': $isOld == 1}">
                  <div class="name" :class="{'isOld32': $isOld == 1}">{{item.userName}}</div>
                  <div class="detail">
                    <div class="sex">{{item.sex == 1 ? '男' : '女'}}</div>
                    <div class="age">{{item.age}}</div>
                    <div class="phone">{{item.mobile}}</div>
                  </div>
                  <div class="idNumber">{{item.idNumber}}</div>
                </div>
                <a :href="'tel:'+ item.decode" @click.stop=""><img class="mobile" :src="require('@/assets/img/newVersion/mobile.png')" alt=""></a>
              </div>
              <img class="mid-right" :src="require('@/assets/img/newVersion/right.png')" alt="">
            </div>
            <div class="down" :class="{'isOld28': $isOld == 1}">
              <div class="down-left" v-if="!item.rentFinishDate">入住时间：{{item.createTime}}</div>
              <div class="down-left " v-if="item.rentFinishDate&&!item.timesss" >租赁结束时间：{{item.rentFinishDate}}{{item.timesss}}</div>
              <div class="down-left colorsss" v-if="item.rentFinishDate&&item.timesss" >租赁结束时间：{{item.rentFinishDate}}</div>
                            <div class="down-right" v-if="item.relationshipStr != '本人'" @click.stop="moveOutShow(item.id,item.userName,item.userId)">搬离</div>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </van-pull-refresh>
      <div
          class="add-box"
          @click="goAdd(1)"
          @mousedown.stop="down"
          @touchstart.stop="down"
          @mousemove.stop="move"
          @touchmove.stop="move"
          @mouseup.stop="end"
          @touchend.stop="end"
          ref="fu"
      >
        <div class="add-img">
          <img class="add-icon" :src="require('@/assets/img/newVersion/add.png')" alt="">
          <div class="text">新增、添加</div>
        </div>
      </div>
      <van-dialog
          v-model="control.moveOutShow"
          cancel-button-text="取消"
          confirm-button-text="确定"
          show-confirm-button
          show-cancel-button
          @confirm="moveOut"
          :before-close="onBeforeClose"
          @cancel="control.moveOutShow = false">
        <div class="moveContent">
          确定搬离{{show.moveOutName}}?
        </div>
      </van-dialog>
      <van-dialog
          v-model="control.emigrationShow"
          cancel-button-text="取消"
          confirm-button-text="确定"
          show-confirm-button
          show-cancel-button
          @confirm="confirmEmigration"
          :before-close="onBeforeClose"
          @cancel="cancelEmigration">
        <div class="moveContent">
          是否需要迁出本社区？
        </div>
      </van-dialog>
    </van-tab>
  </van-tabs>

</template>

<script>
import {getImageStream} from '@/utils'
import {getVirtualDict} from '@/utils/common'
import UploadFile from "@/components/upload/uploadFile.vue";
import LngLat from "../house-new/map-new.vue";

export default {
  components: {LngLat, UploadFile},
  data() {
    return {
          safetyList:['A','B','C'],
      Family:false,
      userId:this.$globalData.userInfo.userId,
      hostId:'',
      lats:'',
      lngs:'',
      fileList:[],
      uploadPath:'',
      lnglatVisible:false,
      orgRegistryCount:0,
      totalCount:0,
      tsLIst:[],
      searchValue:'',
      searchValueType: 0,
      readonly: true,
      active: '',
      id: '',
      subarea: '',
      page: 0,
      limit: 10,
      dataList: [],
      pullLoading: false,
      loading: false,
      finished: false,
      dataForm: {
              safety:'',
        subareaId:0,
        Bosstel:'',
        tel:'',
        placelat:'',
        lat:'',
        lng:'',
        orgId: this.$globalData.userInfo.orgId,
        newFileMessages:[],
        fileMessages:[],
        deleteFiles:[],
        message:'',
              isAutoRent:false,
        checked:false,
        money:'',
        Tenantareas:'',
        place:'',
        subarea: '',
        fullName: '',
        areas: '',
        floor: '',
        doorModel: '',
        houseType: '',

        buildingId: '',
        unitId: '',
        name: '',
        remark: '',
        code: '',
        manyHomeowners: '',
        residentsLimit: '',
        rentalRoomNum: '',
        collectiveOwnership: '',
        houseCard: '',
        imgUrl: '',
        gridId: '',
        miniGridId: '',
        labels: [],
      },
      temData: {
        areas: '',
        doorModel: '',
        doorModelStr: '',
        houseType: '',
        houseTypeStr: ''
      },
      show: {
        doorModel: '',
        houseType: '',
        moveOutName: ''
      },
      control: {
              safety:false,
        doorModel: false,
        houseType: false,
        moveOutShow: false,
        emigrationShow: false
      },
      moveOutId: '',
      moveOutUserId: '',
      isTenant: false,
      houseType: [],
      doorModelList: [],
      TenantList:[],
      HasList:[],
      houseLabel: [],
      flags: false, //控制使用
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
      timesss:''
    }
  },
  watch:{
    active:{
      handler(val){
        this.readonly=true
        if(document.getElementById('map')){
          document.getElementById('map').style.display="none"
        }
      }
    },
    lnglatVisible:{
      handler(val){
        if(document.getElementById('map')&&this.lnglatVisible==false){
          console.log('关闭地图')
          document.getElementById('map').style.display="none"
        }
      }
    }
  },
  created() {
    this.Family=false,
        this.fileList=[]
    this.dataForm.newFileMessages=[]
    let that = this
    this.id = this.$route.query.id || this.$globalData.userInfo.houseId
    this.community = this.$route.query.community
    this.subarea = this.$route.query.subarea || this.$subarea
    this.uploadPath=`files/house/${this.id}`
    this.getDataList()
    getVirtualDict('doorModel', function (virtualDictList) {
      that.doorModelList = virtualDictList
    })
    getVirtualDict('useType', function (virtualDictList) {
      that.houseType = virtualDictList
    })
    getVirtualDict('rentHouseFacility', function (virtualDictList) {
      that.TenantList = virtualDictList
    })
    this.getHouseInfo()
    this.TenantInfo()
    this.getInfo()
  },
  methods: {
    closed (latlng){
      this.lnglatVisible = false

        if(latlng.lat) {
          this.dataForm.lat = latlng.lat
          this.dataForm.lng = latlng.lng
      this.dataForm.placelat = this.dataForm.lat + ',' + this.dataForm.lng
        }

    },
    filesUpload (files) {

      this.dataForm.newFileMessages = files
      this.dataForm.fileMessages = files
    },
    delFile (id) {
      this.dataForm.deleteFiles.push(id)
    },
    getLngLat () {

      if(!this.readonly) {
        this.lnglatVisible = true
        this.$nextTick(() => {
          console.log(this.dataForm.orgId)
          this.$refs.lnglat.lngs=this.dataForm.lng
          this.$refs.lnglat.lats=this.dataForm.lat
          this.$refs.lnglat.init(this.dataForm.subareaId)
        })
      }
    },
    // getNumber (id) {
    //     this.$http({
    //     url: this.$http.adornUrl(`/wxapp/user/info/decode`),
    //     method: 'get',
    //     params: this.$http.adornParams({
    //         id: id,
    //         type: 2
    //     })
    //     }).then(({data}) => {
    //     if (data.code === 0) {
    //         return data.mobile
    //     }
    //     })
    // },
    comparisonTime(time){
      if(time!=null){
        time=time.slice(0,11)}

      let date = new Date(),
          year = date.getFullYear(), //获取完整的年份(4位)
          month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
          strDate = date.getDate() // 获取当前日(1-31)
      if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0

      let now=new Date(`${year}-${month}-${strDate}`)
      let times=new Date(time)
      return now >= times
    },

    seaname(){
      this.page=0
      this.dataList=[]
      this.searchValueType=1
      if(this.searchValue.length==0){
        this.finished=false
        this.searchValueType=0
      }
      this.getDataList()
    },

    onCancel1() {
      this.dataList=[]
      this.searchValue = "";
      this.searchValueType=0
      this.page=0
      this.finished=false
      this.getDataList()
    },
    RenHouse(){

      this.readonly = false
      console.log(this.$globalData.userInfo.userId)
    },
    getImageStream(value) {
      return getImageStream(value)
    },
    down() {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.$refs.fu.offsetLeft;
      this.dy = this.$refs.fu.offsetTop;
    },
    move() {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
        let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
        this.xPum < 0 && (this.xPum = 0);
        this.yPum < 0 && (this.yPum = 0);
        this.xPum > width && (this.xPum = width);
        this.yPum > height && (this.yPum = height);
        this.$refs.fu.style.left = this.xPum + "px";
        this.$refs.fu.style.top = this.yPum + "px";
        //阻止页面的滑动默认事件
        document.addEventListener("touchmove",function () {
          event.preventDefault();
        },false);
      }
    },
    //
    getInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/jmgg/info/${this.id}`),
        method: 'post',
        params: this.$http.adornParams({
          loginUserId: this.$globalData.userInfo.userId,
          loginUserOrgId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.orgRegistryCount = data.orgRegistry[4].count
        }
      })
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
    getHouseInfo() {

      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/info'),
        method: 'GET',
        params: this.$http.adornParams({
          id: this.id
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.community = data.buildingHouse.community
          this.dataForm.subareaId=data.buildingHouse.subarea
          this.dataForm.subarea = data.buildingHouse.subareaName
          this.dataForm.fullName = data.buildingHouse.fullName
          this.dataForm.areas = data.buildingHouse.areas
          this.temData.areas =  data.buildingHouse.areas
          this.dataForm.floor = data.buildingHouse.floor
          this.dataForm.doorModel = data.buildingHouse.doorModel
          this.temData.doorModel = data.buildingHouse.doorModel
          this.show.doorModel = data.buildingHouse.doorModelStr
          this.temData.doorModelStr = data.buildingHouse.doorModelStr
          this.dataForm.houseType = data.buildingHouse.useType
          this.temData.houseType = data.buildingHouse.useType
          this.show.houseType = data.buildingHouse.useTypeStr
          this.temData.houseTypeStr = data.buildingHouse.useTypeStr
          if(data.labels) {
            // this.houseLabel = data.labels
            this.dataForm.labels = data.labels.map(item => item.id)
            let temFatList = data.labels.filter(item => !item.parentId)
            let temChildList = data.labels.filter(item => item.parentId)
            temFatList.forEach(item => {
              if (temChildList.map(it => it.parentId).indexOf(item.id) == -1) {
                this.houseLabel.push(item)
              }
            })
            data.labels.forEach(item => {
              if(item.depth == 2) {
                temFatList.forEach(it => {
                  if(it.id == item.parentId ) {
                    item.name = it.name + ':' + item.name
                    this.houseLabel.push(item)
                  }
                })
              }
            })
          }
          this.dataForm.buildingId = data.buildingHouse.buildingId
          this.dataForm.unitId = data.buildingHouse.unitId
          this.dataForm.name = data.buildingHouse.name
          this.dataForm.remark = data.buildingHouse.remark
          this.dataForm.code = data.buildingHouse.code
          this.dataForm.manyHomeowners = data.buildingHouse.manyHomeowners
          this.dataForm.residentsLimit = data.buildingHouse.residentsLimit
          this.dataForm.rentalRoomNum = data.buildingHouse.rentalRoomNum
          this.dataForm.collectiveOwnership = data.buildingHouse.collectiveOwnership
          this.dataForm.houseCard = data.buildingHouse.houseCard
          this.dataForm.imgUrl = data.buildingHouse.imgUrl
          this.dataForm.gridId = data.buildingHouse.gridId
          this.dataForm.miniGridId = data.buildingHouse.miniGridId
        }
      })
    },
    //获取租客
    TenantInfo(){
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/rent/info`),
        method: 'get',
        params: this.$http.adornParams({
          'id': this.id
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          if(data.buildingHouseRent.facilities) {
            this.HasList = data.buildingHouseRent.facilities.split(',') || []
          }
          else{
            this.HasList=[]
          }
            this.dataForm.safety=data.buildingHouseRent.safety||''
          this.dataForm.checked=data.buildingHouseRent.isRent==0?true:false
            this.dataForm.isAutoRent=data.buildingHouseRent.isAutoRent==0?true:false
          if(data.buildingHouseRent.univalence) {
            this.dataForm.money = data.buildingHouseRent.univalence == 0.0 ? '面议' : data.buildingHouseRent.univalence
          }
          else{
            this.dataForm.money='面议'
          }
          this.dataForm.tel=data.buildingHouseRent.mobile||this.dataForm.Bosstel
          this.dataForm.Tenantareas=data.buildingHouse.areas||'',
              this.dataForm.lng=data.buildingHouse.codeLon||'',
              this.dataForm.lat=data.buildingHouse.codeLat||''
          if(this.dataForm.lng) {
            this.dataForm.placelat = this.dataForm.lat + ',' + this.dataForm.lng
          }
          else{
            this.dataForm.placelat = ''
          }
          this.dataForm.message=data.buildingHouseRent.remark||''
          if (data.buildingHouseRent.url) {
            this.dataForm.newFileMessages=[]
            this.fileList=[]
            data.buildingHouseRent.url.split(',').forEach((item,index)=> {
              let u;
              u = getImageStream(item)
              let url = {
                uid: this.fileList.length,
                id: index,//多图需要记录一个uid，随图片数量增加而增加
                status: 'done',//uploading上传中，done上传完成，error上传失败
                fileType: 'image',
                relativePath: item,
                url: u,
                realUrl: item.replace(/\\/g, "/")
              }
              this.fileList.push(url);
              this.dataForm.newFileMessages.push(url);
              // this.dataForm.fileList.push(url);
            })
          }
        }
      })

    },
    doorModelConfirm(e) {
      this.show.doorModel = e.label
      this.dataForm.doorModel = e.value
      this.control.doorModel = false
    },
    houseTypeConfirm(e) {
      this.show.houseType = e.label
      this.dataForm.houseType = e.value
      this.control.houseType = false
    },
    oncancel() {
      this.dataForm.areas = this.temData.areas
      this.dataForm.doorModel = this.temData.doorModel
      this.show.doorModel = this.temData.doorModelStr
      this.dataForm.houseType = this.temData.houseType
      this.show.houseType = this.temData.houseTypeStr
      this.readonly = true
    },
      onConfirmSafe(e){
      this.dataForm.safety=e
        this.control.safety=false
      },
      openSafe(){
        if(!this.readonly)
        {
          this.control.safety=!this.control.safety
        }
      },
      onCancelSafe(){
        this.dataForm.safety=''
        this.control.safety=false
      },
    onConfirm() {
      this.$toast.loading({duration: 0,message: '提交中...',forbidClick: true,})
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/update'),
        method: 'POST',
        data: this.$http.adornData({
          id: this.id,
          'buildingId': this.dataForm.buildingId,
          'unitId': this.dataForm.unitId,
          'name': this.dataForm.name,
          'remark': this.dataForm.remark,
          'code': this.dataForm.code,
          'manyHomeowners': this.dataForm.manyHomeowners,
          'residentsLimit': this.dataForm.residentsLimit,
          'rentalRoomNum': this.dataForm.rentalRoomNum,
          'collectiveOwnership': this.dataForm.collectiveOwnership,
          'houseCard': this.dataForm.houseCard,
          'imgUrl': this.dataForm.imgUrl,
          'gridId': this.dataForm.gridId,
          'miniGridId': this.dataForm.miniGridId,
          labels: this.dataForm.labels.toString(),
          floor: this.dataForm.floor,
          areas: this.dataForm.areas,
          doorModel:this.dataForm.doorModel,
          useType: this.dataForm.houseType,
          community: this.community,
          updateUserId: this.$userId,
        })
      }).then(({data}) => {
        this.$toast.clear()
        if(data && data.code === 0) {
          this.$toast.success({
            message: '提交成功',
            duration: 1500,
          })
          setTimeout(() => {
            this.getHouseInfo()
            this.readonly = true
          },1500)
        }else {
          this.$toast.fail(data.msg)
        }
      })
    },
    onConfirm1() {
      let rule= /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      const regex = /^\d+(\.\d+)?$/;
      if(this.dataForm.Tenantareas<0){
        return this.$toast.fail('面积不能小于0')
      }
      if(this.dataForm.money!='面议'&&regex.test(this.dataForm.money)==false){
        return this.$toast.fail('金额只能输入数字或者面议')
      }
      if(this.dataForm.money>999999){
        return this.$toast.fail('金额不能大于999999')
      }
      if(this.dataForm.moey==''){
        return this.$toast.fail('手机号不能为空')
      }
      if(!rule.test(this.dataForm.tel)){
        return this.$toast.fail('请输入正确手机号格式')
      }
      this.$toast.loading({duration: 0,message: '提交中...',forbidClick: true,})
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/rent/update'),
        method: 'POST',
        data: this.$http.adornData({
            'safety':this.dataForm.safety||'',
          'id': parseInt(this.id),
          'mobile':this.dataForm.tel,
          'facility': this.HasList,
          'isRent':this.dataForm.checked==true?0:1,
            'isAutoRent':this.dataForm.isAutoRent==true?0:1,
          'univalence':this.dataForm.money=='面议'?0.0:this.dataForm.money,
          'areas':this.dataForm.Tenantareas,
          'codeLon':this.dataForm.lng,
          'codeLat':this.dataForm.lat,
          'remark':this.dataForm.message,
          'fileMessage': this.dataForm.newFileMessages
        })
      }).then(({data}) => {
        this.$toast.clear()
        if(data && data.code === 0) {
          this.$toast.success({
            message: '提交成功',
            duration: 1500,
          })
          setTimeout(() => {
            this.TenantInfo()
            this.readonly = true
          },1500)
        }else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getDataList() {
      this.page++
      this.$toast.loading({duration: 0, message: "加载中...",forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/resident/list'),
        method: 'GET',
        params: this.$http.adornParams({
          searchValueType:this.searchValueType,
          searchValue:this.searchValue,
          page: this.page,
          limit: this.limit,
          houseId: this.id,
          orgId: this.$orgId,
          userId: this.$userId,

        })
      }).then(({data}) => {
        this.$toast.clear()
        if(data && data.code === 0) {
          this.pullLoading = false
          this.totalCount=data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          data.page.list.map(item => {
            if(this.userId==item.userId){
              this.Family=true
            }
            this.$http({
              url: this.$http.adornUrl(`/wxapp/user/info/decode`),
              method: 'get',
              params: this.$http.adornParams({
                id: item.userId,
                type: 2
              })
            }).then(({data}) => {
              if (data.code === 0) {
                if(item.relationshipStr=='本人'&& this.dataForm.tel==''){
                  this.dataForm.Bosstel=data.mobile
                }

                item.decode = data.mobile
              }
            })
          })

          this.dataList = this.dataList.concat(data.page.list)
          this.tsLIst=this.tsLIst.map(item=>{
            return{
              ...item,
              timesss:this.comparisonTime(item.rentFinishDate)
            }
          })
          // 加载状态结束
          this.loading = false
        }
        else {
          this.$toast.fail(data.msg)
        }
      })
    },
        moveOutShow(id,name,userId) {
      this.control.moveOutShow = true
            this.moveOutId = id
            this.show.moveOutName = name
            this.moveOutUserId = userId
    },
    onBeforeClose (action, done) {
      if (action === "confirm") {
        return done(false);
      } else {
        return done();
      }
    },

    moveOut(id,name) {
      // this.$dialog({
      //     message: `确认迁出${name}？`,
      //     confirmButtonText: '确认',
      //     showCancelButton: true,
      //     cancelButtonText:'取消',
      // }).then(() => {
      let checkType = ''
      if (this.$globalData.userInfo.assistId) { // 协管员搬离
        checkType = 10
      } else if (this.$userId != this.moveOutUserId && !this.isTenant) { // 居民搬离居民
        checkType = 9
      } else if (this.$userId != this.moveOutUserId && this.isTenant) { // 居民搬离租客
        checkType = 11
      } else if (this.$userId == this.moveOutUserId) { // 租客搬离租客
        checkType = 12
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/res/houseRes/moveOut'),
        method: 'POST',
        params: this.$http.adornParams({
          id: this.moveOutId,
          modelType: this.isTenant ? 1 : 2,
          // operator: this.$userId,
          checkType
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.$toast.success({
            message: '操作成功',
            duration: 1500,
          }),
              setTimeout(() => {
                this.control.moveOutShow = false
                if(!data.haveHouse) {
                  this.confirmEmigration()
                }else {
                  if(this.$userId == this.moveOutUserId) {
                    this.$router.go(-1)
                  }else {
                    this.page = 0
                    this.dataList = []
                    this.getDataList()
                  }
                }

              },1500)
        }
      })
      // })
    },
    // 迁出社区
    confirmEmigration() {
      let checkType = ''
      if (this.$globalData.userInfo.assistId) { // 协管员迁出
        checkType = 8
      } else if (this.$userId != this.moveOutUserId && !this.isTenant) { // 居民迁出居民
        checkType = 2
      } else if (this.$userId != this.moveOutUserId && this.isTenant) { // 居民迁出租客
        checkType = 13
      } else if (this.$userId == this.moveOutUserId) { // 租客迁出租客
        checkType = 14
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/res/moveOutCommunity'),
        method: 'POST',
        params: this.$http.adornParams({
          id: this.moveOutUserId,
          community: this.community,
          checkType //居民2 协管员迁出8
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.$toast.success({
            message: '已提交',
            duration: 1500,
          }),
              setTimeout(() => {
                this.control.emigrationShow = false
                if(this.$userId == this.moveOutUserId) {
                  this.$router.go(-1)
                }else {
                  this.page = 0
                  this.dataList = []
                  this.getDataList()
                }
              },1500)
        }else {
          this.$toast.fail(data.msg)
        }
      })
    },
    cancelEmigration() {
      this.control.emigrationShow = false
      if(this.$userId == this.moveOutUserId) {
        this.$router.go(-1)
      }else {
        this.page = 0
        this.dataList = []
        this.getDataList()
      }
    },
    onPullDownRefresh(e) {
      this.finished = false;
      this.dataList = [];
      this.page = 0;
      this.getDataList();
    },
    goAdd(isSelf) {
      this.$router.push({
        path: '/newRegister',
        query: {
          isSelf,
          title: '添加家人',
          houseId: this.id,
          subarea: this.subarea,
          houseName: this.$route.query.houseName || this.$houseName,
          myHouse: 1,
          isList: 1,
          isFamily: 1
        }
      })
    },
    goUserInfo(id,peopleId,relationship,relationshipStr) {
      let houseName = this.dataForm.subarea + this.dataForm.fullName
      this.$router.push({
        path: '/peopleAdd',
        query: {
          id,
          peopleId,
          houseId: this.id,
          relationship,
          relationshipStr,
          houseName,
          isChangeHouse: 1,
          isFamily: 1
        }
      })
    }
  }
}
</script>

<style scoped>
.isOld40 >>> .van-cell {
  font-size: 40px;
}
.isOld40 >>> .van-field__control {
  font-size: 40px !important;
}
.van-cell >>> .van-field__label {
  width: auto;
  margin-right: 32px;
}
.van-cell >>> .van-field__control {
  font-size: 34px;
}
.van-dialog {
  width: 560px;
  border-radius: 16px;
}
.van-dialog >>> .van-dialog__content {
  margin: 38px 0px 40px 0px;
  text-align: center;
}
</style>
<style lang="scss" scoped>

// .van-form {
//     /deep/ .van-field__label {
//         width: none;
//         margin-right: 32px;
//     }
// }
.moveContent {
  font-size: 30px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #333333;
}
.base-info {
  width: 100%;
  height: 96px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  .left {
    font-size: 32px;
    font-family: 'PingFangSC-Semibold';
    font-weight: 600;
    color: #333333;
  }
  .right {
    width: 88px;
    height: 52px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 2px solid #4581F8;
    font-size: 24px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #4581F8;
    line-height: 52px;
    text-align: center;
  }
}
.base-form {
  .house-labels {
    width: 100%;
    // height: 166px;
    background: #FFFFFF;
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 26px;
    .label {
      // width: 172px;
      height: 48px;
      background: #F3F3F3;
      border-radius: 8px;
      border: 2px solid #D9D9D9;
      font-size: 26px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #666666;
      line-height: 48px;
      text-align: center;
      margin-top: 16px;
      margin-right: 24px;
      padding: 0 16px;
    }
  }
}
.btns {
  font-size: 32px;
  font-family: 'PingFangSC-Medium';
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .confirm {
    width: 50%;
    height: 88px;
    background: #4581F8;
    color: #FFFFFF;
    border-radius: 8px;
  }
  .cancel {
    width: 50%;
    height: 88px;
    background: rgba(69,129,248,0);
    border-radius: 8px;
    border: 2px solid #4581F8;
    color: #4581F8;
    margin-right: 40px;
  }
}
.items {
  background: #FFFFFF;
  padding: 0 30px;
  margin-bottom: 16px;
  .top {
    height: 46PX;
    line-height: 46PX;
    border-bottom: 0.5PX solid rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-left {
      font-size: 15PX;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
    }
    .top-right {
      // width: 136px;
      height: 52px;
      background: rgba(78,124,245,0.12);
      border-radius: 8px;
      text-align: center;
      line-height: 52px;
      font-size: 24px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #4E7CF5;
      padding: 0 10px;
    }
  }
  .mid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 188px;
    margin-top: 22px;
    padding-bottom: 26px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    .mid-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header {
        width: 120px;
        height: 120px;
      }
      .personal-info {
        display: flex;
        flex-direction: column;
        font-size: 28px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #666666;
        margin-left: 16px;
        margin-right: 26px;
        .name {
          font-size: 28px;
          font-family: 'PingFangSC-Semibold';
          font-weight: 600;
          color: #333333;
        }

        .detail {
          display: flex;
          align-items: center;
          margin: 10px 0;
          .age {
            margin: 0 20px;
          }
          .phone {
            color: #4581F8;
          }
        }
      }
      .mobile {
        width: 40px;
        height: 40px;
      }
    }
    .mid-right {
      width: 16px;
      height: 28px;
    }
  }
  .down {
    height: 80px;
    // line-height: 40px;
    font-size: 24px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #999999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .down-right {
      width: 136px;
      height: 52px;
      background: #FFFFFF;
      border-radius: 8px;
      border: 2px solid #4581F8;
      color: #4581F8;
      text-align: center;
      line-height: 52px;
    }
  }
}
.add-box {
  position: fixed;
  bottom: 72px;
  right: 0;
  width: 206px;
  height: 214px;
  .add-img {
    position: relative;
    .add-icon {
      width: 100%;
      height: 100%;
    }
    .text {
      position: absolute;
      left: 56px;
      bottom: 52px;
      font-size: 20px;
      // font-family: PingFangSC-Medium;
      font-weight: 500;
      color: #FFFFFF;
    }
  }

}
.colorsss{
  color:red !important;
}
.search-top {
  position: relative;
  height: 112px;
  padding: 24px 30px;
  .search-input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgba(0,0,0,0.04);
    border-radius: 8px;
    padding-left: 60px;
    font-size: 26px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    &::placeholder {
      color: #999999;
    }
  }
  .search-clear {
    position: absolute;
    top: 40px;
    right: 50px;
    width: 30px;
    height: 30px;
  }
  .search-icon {
    position: absolute;
    top: 38px;
    left: 42px;
    width: 36px;
    height: 36px;

  }
}
</style>
<style scoped>
.van-tabs >>> .van-tab__text--ellipsis {
  font-size: 32px;
}
.mapA{
  border-radius: 10px;
  color: #4581F8;
  width: 750px;
  z-index: 10000;
  position: absolute;
  padding: 10px;
  font-size: 34px;
  font-family: 'PingFangSC-Regular',serif;
  background-color: #F5F5F5;
}
.lat{
  flex: 4;
  color:black;
  text-align: left;
}
.safe{
  .van-cell__value{
    color: #000000;
    text-align: left;
  }
  .van-cell__title{
    flex: unset;
    margin-right: 24px;
  }
}
</style>
